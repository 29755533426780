.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.mobaku-button {
  position: absolute;
  left: 10px;
  bottom: 130px;
  font-size: 16px;
  background-color: #fff;
  padding: 5px;
  width: 110px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
}

.map-item-shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
}

.geolocation-button {
  position: absolute !important;
  background-color: #fff !important;
  right: 12px;
  bottom: 160px;
  height: 40px;
  width: 40px;
  padding: 10px;
}

.geolocation-button:hover {
  background: "#d9d9d9";
}

.search-menu {
  position: absolute !important;
  background-color: #fff !important;
  top: 10px;
  left: 10px;
  /* // 横幅から判定して検索ボタンを上にずらす
  [theme.breakpoints.down("sm")]: {
    top: "50px",
  },
  [theme.breakpoints.up("md")]: {
    top: "150px",
  }, */
  z-index: 2;
  width: 320px !important;
  height: 52px;
  border: solid thin #ddd;
  border-radius: 4px;
  background-color: #fff;
  padding: 2px;
}

.search-button {
  position: absolute !important;
  background-color: #fff !important;
  top: 10px;
  left: 10px;
  padding: 5px 10px;
  margin: 2px;
  font-size: 12px;
  color: white;
  height: 40px;
}

.search-button:hover {
  background: #35ab63 !important;
}

.search-text-field {
  margin: 2px 0px 2px 2px;
  padding: 0px 2px;
  height: 20px;
}
.close-button {
  position: absolute !important;
  top: -5px;
  left: 90%;
  width: 12px;
}

.search-main-button {
  padding: 5px 10px;
  margin: 2px;
  background-color: #35ab63;
  font-size: 12px;
  color: white;
  height: 40px;
}

.display-manager-menu {
  position: absolute !important;
  background-color: #fff !important;
  bottom: 60px;
  left: 38%;
  width: 360px !important;
  height: 52px;
  border: solid thin #ddd;
  border-radius: 4px;
  padding: 2px;
}

.display-manager-menu-close {
  position: relative;
  right: 5px;
}

.display-manager-button {
  position: absolute !important;
  background-color: #fff !important;
  bottom: 60px;
  left: 48%;
  margin: 2px;
  font-size: 12px;
  color: white;
  height: 46px;
  width: 46px;
}

.display-manager-button:hover {
  background-color: #35ab63 !important;
}

.display-manager-inner-button-on {
  color: #fff !important;
  background-color: #35ab63 !important;
}

.display-manager-inner-button-off {
  color: #000 !important;
  background-color: #fff !important;
}

